import React from 'react';
import {graphql, StaticQuery} from 'gatsby'
import PropTypes from "prop-types";
import ImageLinkCard from "../../../../components/ImageLinkCard";
import GridWrapper from "../../../../components/GridWrapper";
import styles from './styles.module.scss';

const BlogArticlesWrapper = ( {data} ) => {
  const blogPages = data.allMarkdownRemark.edges;

  return (
    <section id="blog-articles">
      <h2 className={styles.sectionTitle}>
        Blog posts
      </h2>

      <GridWrapper columns={3}>
        {blogPages.map( (page, index) => (
          <ImageLinkCard
              image={page.node.frontmatter.featuredImage}
              title={page.node.frontmatter.title}
              link={page.node.fields.slug}
              key={index}
          />
        ))}
      </GridWrapper>
    </section>
  );
};

BlogArticlesWrapper.propTypes = {
  data: PropTypes.object
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogArticlesWrapperQuery {
        allMarkdownRemark(
          filter: {frontmatter: {tags: {nin: "Educator in Focus"}}},
          sort: {fields: frontmatter___date, order: DESC}
        ) {
          edges {
            node {
              html
              frontmatter {
                date
                featuredImage
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={ (data) => <BlogArticlesWrapper data={data} /> }
  />
)
